import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { Typography, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const { t } = useTranslation("common");

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const subjectEncoded = encodeURIComponent(subject);
    const bodyEncoded = encodeURIComponent(body);
    window.location.href = `mailto:ronnytang_@hotmail.com?subject=${subjectEncoded}&body=${bodyEncoded}`;
  };

  return (
    <Container sx={{ py: 2 }}>
      <Helmet>
        {/* Google tag (gtag.js)  */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-KS3PY794J1"
        ></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-KS3PY794J1');
      `}
        </script>
        <title>JV Bumijaya | {t("common:contactUs")}</title>
        <meta
          name="description"
          content="View our current projects and services"
        />
      </Helmet>
      <Typography variant="h4" component="h1" gutterBottom>
        {t("common:contactUs")}
      </Typography>
      <Typography component="h2" variant="body1" gutterBottom>
        {t("common:moreInformation")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          label={t("subject")}
          value={subject}
          onChange={handleSubjectChange}
          sx={{ pb: 2 }}
        />
        <TextField
          required
          fullWidth
          multiline
          rows={5}
          label={t("body")}
          value={body}
          onChange={handleBodyChange}
          sx={{ pb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary">
          {t("send")}
        </Button>
      </form>
    </Container>
  );
};

export default ContactUs;
