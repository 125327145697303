import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation("notFound");
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>{t("pageTitle")}</h1>
      <Link to="/">{t("goHome")}</Link>
    </div>
  );
};

export default NotFound;
