import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./assets/App.css";
import Home from "./components/Home/Home";
import Projects from "./components/Projects/Projects";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/NavBar/NavBar";
import NotFound from "./components/NotFound";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
// override MUI drawer globally as currently unable to override the style at MuiDrawer directly
const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          marginTop: "56px",
        },
      },
    },
  },
});

function Layout({ children }) {
  return (
    <div>
      <NavBar />
      {children}
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

function App() {
  const location = useLocation();
  const isChinese = location.pathname.startsWith("/ch");
  const { i18n } = useTranslation();

  useEffect(() => {
    isChinese && i18n.changeLanguage("ch");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route
            exact
            path={isChinese ? "/ch" : "/"}
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            exact
            path={isChinese ? "/ch/projects" : "/projects"}
            element={
              <Layout>
                <Projects />
              </Layout>
            }
          />
          <Route
            exact
            path={isChinese ? "/ch/contactUs" : "/contactUs"}
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />
          <Route path="*" element={<NotFound status={404} />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
