import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (lng) => {
    const currentPath = location.pathname;
    const basePath = currentPath.startsWith("/")
      ? currentPath.substring(1)
      : currentPath; // Remove leading slash
    const pathSegments = basePath.split("/").filter(Boolean); // Filter out empty segments

    // Handle cases where there's a language code in the path
    if (pathSegments.length > 0 && ["ch", "en"].includes(pathSegments[0])) {
      pathSegments.shift(); // Remove the existing language code
    }

    const newPath =
      lng === "en"
        ? `/${pathSegments.join("/")}`
        : `/${lng}/${pathSegments.join("/")}`;

    i18n.changeLanguage(lng);
    navigate(newPath, { replace: true });
  };

  return (
    <div>
      <select
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language}
      >
        <option value="en">English</option>
        <option value="ch">中文</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
