import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import home_bg from "../../assets/home_bg.jpg";

function HomeHeader() {
  const { t } = useTranslation(["localBusinessSchema, daysOfWeek"]);

  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: t("localBusinessSchema:name"),
    description: t("localBusinessSchema:description"),
    url: "https://www.jvbumijaya.com",
    telephone: "+6082-462996",
    image: home_bg,
    address: {
      "@type": "PostalAddress",
      streetAddress:
        "2nd floor, No.4, Block A, Section 64, KTLD, Jalan Tun Jugah",
      addressLocality: "Kuching",
      postalCode: "93350",
      addressCountry: "Malaysia",
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        t("daysOfWeek:monday"),
        t("daysOfWeek:tuesday"),
        t("daysOfWeek:wednesday"),
        t("daysOfWeek:thursday"),
        t("daysOfWeek:friday")
      ],
      opens: "08:00",
      closes: "17:00",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 1.532256,
      longitude: 110.347093,
    },
    hasMap: "https://g.page/JV-Bumijaya-Sdn-Bhd?share",
    makesOffer: [
      {
        "@type": "Offer",
        itemOffered: {
          "@type": "Service",
          name: t("localBusinessSchema:sandDredgingTitle"),
          description: t("localBusinessSchema:sandDredgingDescription"),
        },
      },
      {
        "@type": "Offer",
        itemOffered: {
          "@type": "Service",
          name: t("localBusinessSchema:earthSupplyTitle"),
          description: t("localBusinessSchema:earthSupplyDescription"),
        },
      },
      {
        "@type": "Offer",
        itemOffered: {
          "@type": "Service",
          name: t("localBusinessSchema:contractServicesTitle"),
          description: t("localBusinessSchema:contractServicesDescription"),
        },
      },
    ],
  };

  return (
    <Helmet>
      {/* Google tag (gtag.js)  */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-KS3PY794J1"
      ></script>
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-KS3PY794J1');
      `}
      </script>
      <title>{t("localBusinessSchema:name")}</title>
      <link rel="alternate" href="https://www.jvbumijaya.com/" hreflang="en" />
      <link rel="alternate" href="https://www.jvbumijaya.com/ch" hreflang="ch" />
      <meta
        name="description"
        content={t("localBusinessSchema:description")}
      />
      <script type="application/ld+json">{JSON.stringify(localBusinessSchema)}</script>
    </Helmet>
  );
}

export default HomeHeader;
