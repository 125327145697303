import { styled } from "@mui/material/styles";
import homeBg from "../../assets/home_bg.jpg";

import {
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

export const HomeBox = styled('div')({
  position: "relative",
});

export const Image = styled('div')(({ theme }) => ({
  background: `url(${homeBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "50% 90%",
  minHeight: "95vh",
  [theme.breakpoints.down("md")]: {
    minHeight: "50vh",
  },
  [theme.breakpoints.down("xs")]: {
    minHeight: "35vh",
    backgroundPosition: "center top",
  },
}));

export const Text = styled('div')(({ theme }) => ({
  color: "rgba(255, 255, 255, 1)",  // Changed rgba values to be within 0-255
  textShadow: "0 0 25px black",
  padding: "0 30%",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    color: "black",
    textShadow: "none",
    padding: "0 0",
    textAlign: "left",
  },
}));

export const TextTitle = styled('div')({
  fontWeight: "bold",
});

export const TextBox = styled('div')(({ theme }) => ({
  position: "absolute",
  boxSizing: "border-box",
  width: "100%",
  height: "20%",
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    position: "relative",
    display: "block",
    height: "auto",
  },
}));

export const CoreServicesSection = styled('section')(({ theme }) => ({
  backgroundColor: "#fafafa", // White background
  padding: theme.spacing(8, 0),
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(6),
  fontWeight: "bold",
  color: "#000000", // Black text to contrast with white background
  textTransform: "uppercase",
}));

export const MediaContainer = styled('div')(({ theme }) => ({
  position: "relative",
  paddingTop: "75%", // 4:3 aspect ratio
  height: 0,
  overflow: "hidden",
}));

export const ServiceCard = styled(Card)(({ theme }) => ({
  width: "100%", // Ensure card takes up full width of its grid item
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.shape.borderRadius * 3, // Rounded corners
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)", // Increased shadow
  [theme.breakpoints.down("sm")]: {
    boxShadow: "none", // Remove shadow on mobile
    borderRadius: 0,
  },
}));
export const ServiceCardMedia = styled(CardMedia)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

export const ServiceCardContent = styled(CardContent)({
  flexGrow: 1,
  backgroundColor: "#ffffff", // White background for text area
});

export const ServiceCardTitle = styled(Typography)({
  fontWeight: "bold",
  color: "#000000", // Black text for contrast
  textAlign: "center",
});
