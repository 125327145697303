import React from "react";
import { Typography, Container, Grid, Box, Divider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const imagesSandDredging = require.context(
  "../../assets/sand_dredging",
  false,
  /\.(png|jpe?g|svg)$/
);
const imageFilesSandDredging = imagesSandDredging
  .keys()
  .map((imagePath) => imagesSandDredging(imagePath));

const imagesLandFill = require.context(
  "../../assets/land_filling",
  false,
  /\.(png|jpe?g|svg)$/
);
const imageFilesLandFill = imagesLandFill
  .keys()
  .map((imagePath) => imagesLandFill(imagePath));

const imagesEarthWork = require.context(
  "../../assets/earth_supply",
  false,
  /\.(png|jpe?g|svg)$/
);
const imageFilesEarthWork = imagesEarthWork
  .keys()
  .map((imagePath) => imagesEarthWork(imagePath));

const imagesContractServices = require.context(
  "../../assets/contract_services",
  false,
  /\.(png|jpe?g|svg)$/
);
const imageFilesContractServices = imagesContractServices
  .keys()
  .map((imagePath) => imagesContractServices(imagePath));

const ImageBox = ({ image }) => (
  <Box
    sx={{
      width: "100%",
      paddingTop: "75%", // 4:3 aspect ratio
      position: "relative",
      overflow: "hidden",
      borderRadius: "4px",
    }}
  >
    <img
      src={image}
      alt=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
    />
  </Box>
);

const ProjectSection = ({ title, images, description }) => {
  const uniqueImages = Array.from(new Set(images));

  return (
    <Box sx={{ mb: 6 }}>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {uniqueImages.map((image, index) => (
          <Grid item xs={6} md={4} key={image}>
            <ImageBox image={image} />
          </Grid>
        ))}
      </Grid>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {description}
      </Typography>
      <Divider />
    </Box>
  );
};

function Projects() {
  const { t } = useTranslation("projects");

  return (
    <Container maxWidth="lg">
      <Helmet>
        {/* Google tag (gtag.js)  */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-KS3PY794J1"
        ></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-KS3PY794J1');
      `}
        </script>
        <title>{t("pageTitle")}</title>
        <meta name="description" content={t("metaDescription")} />
        <link
          rel="alternate"
          href="https://www.jvbumijaya.com/projects"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://www.jvbumijaya.com/ch/projects"
          hreflang="ch"
        />
      </Helmet>
      <Box sx={{ py: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="left"
          sx={{ mb: 4, fontWeight: "bold" }}
        >
          {t("pageHeading")}
        </Typography>
        <ProjectSection
          title={t("sandDredgingTitle")}
          images={imageFilesSandDredging}
          description={t("sandDredgingDescription")}
        />
        <ProjectSection
          title={t("landFillingTitle")}
          images={imageFilesLandFill}
          description={t("landFillingDescription")}
        />
        <ProjectSection
          title={t("earthSupplyTitle")}
          images={imageFilesEarthWork}
          description={t("earthSupplyDescription")}
        />
        <ProjectSection
          title={t("contractServicesTitle")}
          images={imageFilesContractServices}
          description={t("contractServicesDescription")}
        />
      </Box>
    </Container>
  );
}

export default Projects;
