import {
  Drawer,
  List,
  ListItemText,
  IconButton,
  ListItemButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

const DrawerComp = ({ value, setValue }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const location = useLocation();
  const isChinese = location.pathname.startsWith("/ch");
  const { t } = useTranslation(["home", "common"]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleProjectsToggle = () => {
    setProjectsOpen(!projectsOpen);
  };

  return (
    <>
      <IconButton onClick={handleDrawerToggle} sx={{ ml: "auto" }}>
        <MenuIcon sx={{ color: "black" }} />
      </IconButton>
      <Drawer anchor="top" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          <ListItemButton
            selected={value === 0}
            onClick={() => {
              setValue(0);
              handleDrawerToggle();
            }}
            component={Link}
            to={isChinese ? "/ch" : "/"}
          >
            <ListItemText primary={t("common:home")} />
          </ListItemButton>
          <ListItemButton
            selected={value === 1}
            onClick={() => {
              setValue(1);
              handleProjectsToggle();
            }}
          >
            <ListItemText primary={t("common:projects")} />
          </ListItemButton>
          {projectsOpen && (
            <List>
              <ListItemButton
                sx={{ pl: 3 }}
                selected={value === 2}
                onClick={() => {
                  setValue(2);
                  handleDrawerToggle();
                }}
                component={Link}
                to={isChinese ? "/ch/projects" : "/projects"}
              >
                <ListItemText primary={t("sandDredgingTitle")} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 3 }}
                selected={value === 3}
                onClick={() => {
                  setValue(3);
                  handleDrawerToggle();
                }}
                component={Link}
                to={isChinese ? "/ch/projects" : "/projects"}
              >
                <ListItemText primary={t("earthSupplyTitle")} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 3 }}
                selected={value === 4}
                onClick={() => {
                  setValue(4);
                  handleDrawerToggle();
                }}
                component={Link}
                to={isChinese ? "/ch/projects" : "/projects"}
              >
                <ListItemText primary={t("contractServicesTitle")} />
              </ListItemButton>
            </List>
          )}
          <ListItemButton
            selected={value === 5}
            onClick={() => {
              setValue(5);
              handleDrawerToggle();
            }}
            component={Link}
            to={isChinese ? "/ch/ContactUs" : "/ContactUs"}
          >
            <ListItemText primary={t("common:contactUs")} />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
};

export default DrawerComp;
