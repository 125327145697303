import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import DrawerComp from "./DrawerComp";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as Logo } from "../../assets/jv_logo.svg";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";

function NavBar() {
  const [value, setValue] = useState(false);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null); // state for the dropdown menu
  const location = useLocation();
  const isChinese = location.pathname.startsWith("/ch");
  const { t } = useTranslation(["home", "common"]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#ffffff",
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Toolbar>
        <SvgIcon style={{ fontSize: 56 }}>
          <Logo />
        </SvgIcon>
        <Typography
          pl={2}
          variant="h6"
          component={Link}
          to={
            isChinese ? "https://jvbumijaya.com/ch" : "https://jvbumijaya.com"
          }
          sx={{ textDecoration: "none", color: "#000" }}
        >
          JV Bumijaya
        </Typography>
        {isSmallScreen ? (
          <DrawerComp value={value} setValue={setValue} />
        ) : (
          <Tabs
            value={value}
            onChange={handleTabChange}
            sx={{
              ml: "auto",
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab
              label={t("common:home")}
              to={isChinese ? "/ch" : "/"}
              component={Link}
              sx={{ textTransform: "none", color: "#000" }}
            />
            <Tab
              label={t("common:projects")}
              aria-controls="projects-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              sx={{ textTransform: "none", color: "#000" }}
            />
            <Menu
              id="projects-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to={isChinese ? "/ch/projects" : "/projects"}
              >
                {t("sandDredgingTitle")}
              </MenuItem>
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to={isChinese ? "/ch/projects" : "/projects"}
              >
                {t("earthSupplyTitle")}
              </MenuItem>
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to={isChinese ? "/ch/projects" : "/projects"}
              >
                {t("contractServicesTitle")}
              </MenuItem>
            </Menu>
            <Tab
              label={t("common:contactUs")}
              to={isChinese ? "/ch/contactUs" : "/contactUs"}
              component={Link}
              sx={{ textTransform: "none", color: "#000" }}
            />
          </Tabs>
        )}

        <LanguageSwitcher />
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
