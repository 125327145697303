import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CoreServicesSection,
  SectionTitle,
  MediaContainer,
  ServiceCard,
  ServiceCardMedia,
  ServiceCardContent,
  ServiceCardTitle,
} from "./homeStyles"; // Corrected import
import sand_dredging from "../../assets/sand_dredging.jpg";
import earthwork from "../../assets/earthwork.jpg";
import contract_service from "../../assets/contract_service.jpg";

const CoreServices = () => {
  const { t } = useTranslation("home");

  const services = [
    {
      title: t("sandDredgingTitle"),
      description: t("sandDredgingDescription"),
      image: sand_dredging,
    },
    {
      title: t("earthSupplyTitle"),
      description: t("earthSupplyDescription"),
      image: earthwork,
    },
    {
      title: t("contractServicesTitle"),
      description: t("contractServicesDescription"),
      image: contract_service,
    },
  ];

  return (
    <Box component={CoreServicesSection}>
      <SectionTitle variant="h4" component="h2" gutterBottom>
        {t("coreServices").toUpperCase()}
      </SectionTitle>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        sx={{
          paddingX: {
            xs: 0, // paddingX for extra-small screens (default)
            sm: 3,
            md: 3, // paddingX for medium screens and up
          },
        }}
      >
        {" "}
        {/* Use spacing prop */}
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ServiceCard>
              <MediaContainer>
                <ServiceCardMedia
                  component="img"
                  image={service.image}
                  alt={service.title}
                />
              </MediaContainer>
              <ServiceCardContent>
                <ServiceCardTitle variant="h6" component="h3">
                  {service.title.toUpperCase()}
                </ServiceCardTitle>
                <Typography variant="body2">
                  {t(service.description)}
                </Typography>
              </ServiceCardContent>
            </ServiceCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoreServices;
