import { Typography, Container, Box } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import BusinessIcon from "@mui/icons-material/Business";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import CoreServices from "./CoreServices";
import HomeHeader from "./HomeHeader";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Image, TextBox } from "./homeStyles";

const Home = () => {
  const { t, i18n } = useTranslation("home");

  useEffect(() => {
    i18n.loadNamespaces("home");
  }, [i18n]);

  return (
    <Box>
      <HomeHeader />
      <Container maxWidth={false} disableGutters>
        <Image />
        <TextBox
          p={2}
          sx={{
            color: {
              xs: "black", // Black for small screens      color: "black",
              textShadow: "none",
              padding: 16,
              md: "white", // White for medium and larger screens
            },
            textAlign: {
              xs: "left",
              md: "center",
            }, // Center text for all screen sizes
            textShadow: {
              xs: "none", // No text shadow on small screens
              md: "0 0 25px black", // Text shadow for medium and larger screens
            },
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            JV Bumijaya
          </Typography>
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("titleBuild")} <ConstructionIcon /> | {t("titleDevelop")}{" "}
            <BusinessIcon /> |{t("titleTransport")}{" "} <DirectionsBusIcon />
          </Typography>
          <Typography
            component="h2"
            variant="body1"
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            {t("description")}
          </Typography>
        </TextBox>
      </Container>
      <CoreServices />
    </Box>
  );
};

export default Home;
