import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation("common");
  return (
    <div>
      <Container maxWidth="None" sx={{ backgroundColor: "#000000" }}>
        <Typography
          sx={{ fontWeight: "bold", color: "rgba(256, 256, 256, 1)" }}
          variant="h6"
          component="h2"
          pt={2}
        >
          {t("contactUs")}
        </Typography>
        <List sx={{ color: "rgba(256, 256, 256, 1)" }}>
          <ListItem sx={{ p: 0 }}>
            <MailIcon />
            <ListItemText
              sx={{ pl: 1 }}
              primary={`${t("email")}: cara_usia@yahoo.com`}
            />
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <PhoneIcon />
            <ListItemText
              sx={{ pl: 1 }}
              primary={`${t("phone")}:  082-462996`}
            />
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <SmartphoneIcon />
            <ListItemText
              sx={{ pl: 1 }}
              primary={`${t("mobile")}:  019-827-1811`}
            />
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <BusinessIcon />
            <ListItemText
              sx={{ pl: 1 }}
              primary={`${t(
                "address"
              )}:2nd floor, No.4, Block A, Section 64, KTLD, Jalan Tun Jugah, (King Centre) 93350 Kuching Sarawak`}
            />
          </ListItem>
          <Typography
            sx={{ color: "rgba(256, 256, 256, 1)" }}
            variant="body2"
            pt={2}
          >
            © 2024 {t("common:copyRightText")}
          </Typography>
        </List>
      </Container>
    </div>
  );
}

export default Footer;
