import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import chTranslation from "./locales/ch/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    en: enTranslation,
    ch: chTranslation,
  },
  lng: "en", // Default language
  fallbackLng: "en",
  ns: ["home"], // Define namespaces
  defaultNS: "home",
  interpolation: {
    escapeValue: false,
  },
});
